<template>
	<breadcrumb  :home="home" :model="britems" />

	<div class="grid">
		

		<div class="col-12">
			<div class="card">
				<Toast/>
				
				<DataTable ref="dt" :loading="loading2" :value="products" v-model:selection="selectedProducts" dataKey="id"  responsiveLayout="stack">
					<template #header>
						<div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
							<h5 class="m-0">Roster Preview</h5>
							<span class="block mt-2 md:mt-0 p-input-icon-left">
								<Button label="Shuffle" icon="pi pi-refresh" class="ml-2 p-button-info" @click="shuffle()"  />

                                <Button label="Export" icon="pi pi-upload" class="ml-2 p-button-help" @click="exportCSV($event)"  />

								<Button label="Send Roster" icon="pi pi-calendar" class="ml-2 p-button-primary" @click="sendNewRoster()"  />

                            </span>
							

						</div>
					</template>

					<Column field="count" header="#" headerStyle="width:3%;max-width:2rem;"/>
					<Column field="name" header="Name" :sortable="true" headerStyle="width:14%; min-width:10rem;">						
					</Column>
					<Column field="call0" header="Call 1"  headerStyle="width:14%; min-width:10rem;">						
					</Column>
					<Column field="call1" header="Call 2"  headerStyle="width:14%; min-width:10rem;">						
					</Column>
					<Column field="call2" header="Call 3"  headerStyle="width:14%; min-width:10rem;">						
					</Column>
					
					
					
					
					
					
				</DataTable>

				<Dialog v-model:visible="deleteProductDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="product">Are you sure you want to delete <b>{{product.doc_id}}</b>?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteProductDialog = True"/>
						<Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteProduct(product.event_id)" />
					</template>
				</Dialog>

					<Dialog v-model:visible="deleteReminderDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="product">Are you sure you want to send reminder<b>{{product.doc_id}}</b>?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteReminderDialog = True"/>
						<Button label="Yes" icon="pi pi-check" class="p-button-text" @click="sendEmailReminder(product.event_id)" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteProductsDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="product">Are you sure you want to delete the selected products?</span>
					</div>
					<template #footer>
						<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteProductsDialog = false"/>
						<Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteSelectedProducts" />
					</template>
				</Dialog>
			</div>
		</div>
	</div>

</template>

<script>
import {FilterMatchMode} from 'primevue/api';
import SNService from "../../../service/SNService";
import  'primevue/breadcrumb';

export default {
	props: {
    name1: {
      type: String,
      default: 'Vue!'
    }
  },
	data() {
		return {
			home: {icon: 'pi pi-home', to: '/'},
            britems: [
                {label: 'Manage Events', to: '/sn'},
				{label: 'Roster Preview',disabled:true},
            ],
			event_id:null,
			products: null,
			productDialog: false,
			deleteProductDialog: false,
			deleteReminderDialog: false,
			deleteProductsDialog: false,
			product: {},
			selectedProducts: null,
			filters: {},
			submitted: false,
			account: undefined,
			loading2 :true
		}
	},
	productService: null,
	async created() {
		this.srateService = new SNService();
		this.initFilters();
	},
	mounted() {
		console.log(this.$route.params);
		this.srateService.roster(this.$route.params.id,this.$route.params.mid,this.$route.params.creator_allowed).then((data) => {
                this.products = data.data
                this.loading2 = false;
                this.new_disabled=false;
            });	
				
	},
	methods: {
		
		shuffle(){
			this.loading2 = true;
			this.srateService.roster(this.$route.params.id,this.$route.params.mid,this.$route.params.creator_allowed).then((data) => {
                this.products = data.data
                this.loading2 = false;
                this.new_disabled=false;
            });	
		},
		sendNewRoster(){
			this.loading2 = true;
			let roster_data=this.products
			this.srateService.sendroster(this.$route.params.id,this.$route.params.mid,roster_data).then((res)=>{
					console.log(res);
                    this.$toast.add({severity:'success', summary: 'Successful', detail: 'Roster Send!', life: 3000});
					this.$router.push("/roster/approved/"+this.$route.params.id);
					this.loading2 = false;
              
			}).catch(error => {
                                    console.log(error);
									this.loading2 = false;
                                    this.$toast.add({severity:'error', summary: 'Error', detail: error.message, life: 3000});
                                });
		},
		sendRoster(data){
			this.srateService.sendRosterById(data.event_id).then((res)=>{
					console.log(res);
                    this.$toast.add({severity:'success', summary: 'Successful', detail: 'Roster Send!', life: 3000});
              
			}).catch(error => {
                                    console.log(error);
                                    this.$toast.add({severity:'error', summary: 'Error', detail: error.message, life: 3000});
                                });
		},
		
		
		
		exportCSV() {
			this.$refs.dt.exportCSV();
		},
		confirmDeleteSelected() {
			this.deleteProductsDialog = true;
		},
		deleteSelectedProducts() {
			// this.products = this.products.filter(val => !this.selectedProducts.includes(val));
			// this.deleteProductsDialog = false;
			// this.selectedProducts = null;
			this.$toast.add({severity:'success', summary: 'Successful', detail: 'Not Implemented!', life: 3000});
		},
		initFilters() {
            this.filters = {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            }
        }
	}
}
</script>

<style scoped lang="scss">
</style>
